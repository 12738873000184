const MainPage = () => {
    return <header>
        <div className="welcome-title" style={{fontSize: "15pt", margin: "0.5em", marginTop: "0em"}}>
            <div>
                Gergő 
            </div>
            <div>
                City
            </div>
        </div>
    </header>
}

export default MainPage;